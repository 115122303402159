<template>
  <div class="token-card">
    <a
      :href="opensea"
      target="_blank"
      rel="noopener noreferrer"
      class="token-card__link"
    >
      <p class="token-card__badge">
        {{ name }}
      </p>
      <div class="token-card__image">
        <img :src="token.image" alt="ape">
        <div class="token-card__image-badge">
          <IMedal width="24" />
        </div>
      </div>
      <div class="token-card__content">
        <div class="token-card__info">
          <p v-if="token.price" class="token-card__price">
            <span class="token-card__price-value">
              {{ token.price.toFixed(3) }} ETH</span>
          </p>
          <span v-if="token.price">--</span>
          <div v-if="token.meth" class="token-card__meth">
            <img src="@/assets/img/meth.png" alt="meth" width="16">
            {{ token.meth }} METH
          </div>
        </div>
        <div class="token-card__actions">
          <a
            class="token-card__owner-link"
            target="_blank"
            rel="noopener noreferrer"
            :href="`https://opensea.io/${token.ownerAddress}`"
          >
            <IUser width="16" />
          </a>

          <a
            v-if="opensea"
            :href="opensea"
            title="opensea"
            class="token-card__opensea"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="https://storage.googleapis.com/opensea-static/Logomark/Logomark-Blue.svg"
              alt="opensea"
              width="24"
            >
          </a>
        </div>
      </div>
    </a>
  </div>
</template>

<script setup>
import IUser from "@/assets/icons/user.svg?inline";
import IMedal from "@/assets/icons/medal.svg?inline";
import { computed, defineProps, toRef } from "vue";
import { TokensContracts } from "@/config/tokens-contracts";

const props = defineProps({
  token: {
    type: Object,
    default: () => ({}),
    required: true,
  },
  name: {
    type: String,
    required: true,
  },
});

const opensea = computed(() => {
  const contractAddress = Object.values(TokensContracts).find(
    (t) => t.name === props.token.type
  )?.contractAddress;
  if (!contractAddress) return null;
  return `https://opensea.io/assets/ethereum/${contractAddress}/${props.token?.id}`;
});
</script>
