<template>
  <div class="front-page__cards-carousel">
    <FrontPageTokenCard
      v-for="token in tokens"
      :key="token.id"
      :name="token.name"
      :token="token.bull"
    />
  </div>
</template>

<script setup>
import FrontPageTokenCard from "./FrontPageTokenCard.vue";
import { defineProps, toRef } from "vue";

const props = defineProps({
  tokens: {
    type: Array,
    required: true,
  },
});

const tokens = toRef(props, "tokens");
</script>
