<template>
  <div v-if="isLoading" class="front-page">
    <div class="front-page__loader">
      <img class="loader" src="../assets/img/Bulls/bull-placeholder.gif">
    </div>
  </div>
  <div v-else class="front-page">
    <div class="front-navigation">
      <div class="front-navigation__main-btn" v-if="isConnected">
        <a href="#" @click.prevent="connect">
          <span>
            <IWallet style="color: var(--primary)" />
          </span>
          <span>Access My Collection</span>
        </a>
      </div>
      <div v-else class="front-page__connect">
        <button @click="connect" class="front-page__btn front-page__btn-main">Connect Wallet</button>
        <p class="front-page__text front-page__text-sm">
          Connect Your Wallet and View Your Collection, Access The Configurator,
          Harvest $METH, and More!
        </p>
      </div>
    </div>

    <FrontPageNavigation />
    <div class="front-page__banner">
      <RouterLink :to="{ name: 'GodApeQuest' }">
        <img src="@/assets/img/god-ape-quest-banner.jpg" alt="god-ape-quest-banner">
      </RouterLink>
    </div>
    <FrontPageEmailSignup />

    <div class="front-page__sales">
      <h2 class="front-page__heading front-page__heading-center">B.A.P. Window Shopping</h2>
      <TokenCardCarousel :tokens="bapWindowShopping" />
    </div>

    <FrontPageAccomplishments />

    <FrontPageWeeklyApe />

    <!--<div class="front-page__sales">
      <h2 class="front-page__heading front-page__heading-center">
        B.A.P. Window Shopping
      </h2>
      <TokenCardCarousel :tokens="bapWindowShopping" />
    </div>-->

    <!--<StartPageProjects />-->

    <div class="front-page__sales">
      <h2
        class="front-page__heading front-page__heading-center"
      >
Featured Community Member Bulls & Apes
</h2>
      <TokenCardCarousel :tokens="communityBulls" />
    </div>
  </div>
</template>

<script setup>
import TokenCardCarousel from "@/components/FrontPage/TokenCardsCarousel.vue";
import FrontPageNavigation from "@/components/FrontPage/FrontPageNavigation.vue";
import StartPageProjects from "@/components/FrontPage/StartPageProjects.vue";
import FrontPageEmailSignup from "@/components/FrontPage/FrontPageEmailSignup.vue";
import FrontPageAccomplishments from "@/components/FrontPage/FrontPageAccomplishments.vue";
import FrontPageWeeklyApe from "@/components/FrontPage/FrontPageWeeklyApe.vue";
import { computed, onMounted, ref } from "vue";
import { useBapApi } from "@/api/useBapApi";
import { useRouter } from "vue-router";
import { connectWallet, web3Modal } from "@/web3";
import { useWeb3Store } from "@/store/web3";
import IWallet from "@/assets/icons/ape-constructor/ape-constructor-wallet.svg?inline";

const floorBulls = ref([]);
const methBulls = ref([]);
const isLoading = ref(false);
const communityBulls = ref([]);

const isConnected = computed(() => {
  return useWeb3Store().isConnected;
});

const fetchFloorBulls = async () => {
  const data = await useBapApi().exec({
    url: "/collection/list",
    params: {
      sort: "price",
      type: "Bull",
      minprice: 0.001,
      limit: 2,
    },
  });
  if (!data) return;
  const items = data.result.result;
  floorBulls.value = items;
};
const fetchMethBulls = async () => {
  const data = await useBapApi().exec({
    url: "/collection/bestsale",
    params: {
      type: "Bull",
    },
  });
  if (!data) return;
  const items = data.result;
  methBulls.value = items;
};
const fetchData = async () => {
  try {
    await Promise.all([fetchMethBulls(), fetchFloorBulls()]);
  } catch (err) {
    console.error("Error while loading start page bulls", err);
  }
};

onMounted(async () => {
  isLoading.value = true;
  await Promise.all([fetchData(), fetchItems()]);
  isLoading.value = false;
});

const router = useRouter();

const connect = async () => {
  web3Modal.clearCachedProvider();
  const result = await connectWallet();
  if (!result) return;
  router.push({ name: "Collection" });
};

const bapWindowShopping = computed(() => [
  { name: `Floor Bull #${floorBulls.value[0]?.id}`, bull: floorBulls.value[0] },
  { name: `Floor Bull #${floorBulls.value[1]?.id}`, bull: floorBulls.value[1] },
  {
    name: `Max $METH Bull #${methBulls.value[0]?.id}`,
    bull: methBulls.value[0],
  },
  {
    name: `Max $METH Bull #${methBulls.value[1]?.id}`,
    bull: methBulls.value[1],
  },
]);

const communityIds = [6013, 1214, 1888, 30];
const fetchItems = async () => {
  const data = await useBapApi().exec({
    url: "/collection/list",
    params: {
      type: "Bull",
      id: communityIds,
    },
  });
  communityBulls.value = [
    { name: `Bull #${data.result.result[0]?.id}`, bull: data.result.result[0] },
    { name: `Bull #${data.result.result[1]?.id}`, bull: data.result.result[1] },
    { name: `Bull #${data.result.result[2]?.id}`, bull: data.result.result[2] },
    { name: `Bull #${data.result.result[3]?.id}`, bull: data.result.result[3] },
  ];
};
</script>

<style lang="scss">
body:has(.front-page) {
  background-image: url("../assets/img/bg.jpg");
  background-size: cover;
  // sticky background
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  &::before {
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: hsl(0 0% 95% / 0.5);
    z-index: -1;
  }

  & .footer-items a {
    color: #f703ba;
  }

  color: #09090b;
  background-color: #f3f3f3;

  & .BAP-header {
    background-color: #f3f3f3;
    color: #09090b;

    &__drawer {
      &-button svg {
        color: #f3f3f3;
      }
    }

    &__wallet {
      &-info {
        color: #09090b;
        border-color: #09090b;

        & > div:first-of-type {
          border-color: #09090b;
        }
      }
      span {
        color: #09090b;
      }
      svg {
        color: #09090b;
      }
    }
  }
}
</style>
