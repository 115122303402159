<template>
  <div class="front-page__accomplishments">
    <div class="front-page__accomplishments-left">
      <h2 class="front-page__heading">B.A.P. Accomplishments</h2>
      <p class="front-page__subtitle">Don't Know B.A.P. ?</p>
      <p class="front-page__text">
        We've delivered A LOT since our May 31. 2022 mint day.
      </p>
      <a
        href="https://www.bullsandapesproject.com/accomplishments"
        target="_blank"
        rel="noopener noreferrer"
        class="front-page__btn front-page__btn-main"
      >
        See Our Accomplishments
      </a>
    </div>

    <img
      src="https://kajabi-storefronts-production.kajabi-cdn.com/kajabi-storefronts-production/file-uploads/themes/2150661970/settings_images/0c58c7c-03aa-c7c-e873-442f10fd00aa_Accomplishments.webp"
      alt="Bulls and Apes Accomplishments"
      class="front-page__accomplishments-img"
    >
  </div>
</template>
