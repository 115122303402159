<template>
  <div class="front-page__email">
    <div>
      <h2>Join our Newsletter</h2>
      <p class="front-page__text">
        Get the latest news and updates from the Bulls and Apes Project
      </p>
    </div>
    <form class="front-page__form" @submit="onSubmit">
      <input
        type="email"
        name="email"
        class="front-page__email-input"
        placeholder="Enter your email"
      >
      <input
        class="front-page__email-input"
        name="name"
        placeholder="Enter your Full Name"
      >
      <button type="submit" :disabled="loading" class="front-page__email-btn">
        <span v-if="loading">Loading...</span>
        <span v-else>Subscribe</span>
      </button>
      <p>
        <span v-if="error">{{ error }}</span>
      </p>
    </form>
  </div>
</template>

<script setup>
import { ref } from "vue";
import axios from "axios";

const loading = ref(false);
const error = ref(null);
const onSubmit = async (e) => {
  e.preventDefault();
  error.value = null;
  const form = e.target;
  const formData = new FormData(form);
  const data = {
    email: formData.get("email"),
    name: formData.get("name"),
  };

  loading.value = true;

  if (!data.email || !data.name) {
    loading.value = false;
    error.value = "Please enter your email and name";
    return;
  }

  try {
    await axios.post("https://api.convertkit.com/v3/forms/3846527/subscribe", {
      api_key: process.env.VUE_APP_CONVERTKIT_API_KEY,
      ...data,
    });
    form.reset();
  } catch (e) {
    console.log(e);
    error.value = "Something went wrong";
  } finally {
    loading.value = false;
  }
};
</script>
