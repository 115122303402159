<template>
  <div class="front-page__weekly">
    <div v-if="weeklyWinner.loading" class="front-page__weekly-spinner">
      <img src="@/assets/img/spinner-clear.svg" class="loader">
    </div>

    <div v-else class="front-page__weekly-winner">
      <div class="front-page__weekly-info">
        <h2 class="front-page__heading">Ape Weekly Winner</h2>
        <p class="front-page__text">
          Congrats to
          <a target="_blank" :href="weeklyWinner.data?.twitter_link">
            {{ weeklyWinner.data?.twitter_name }}
          </a>
          for this winning design!
        </p>
        <!--<button class="front-page__weekly-btn">See Ape Traits</button>-->
      </div>
      <div class="front-page__weekly-ape">
        <img :src="weeklyWinner.data?.image" alt="Weekly Winner Ape">
      </div>
    </div>
  </div>
</template>

<script setup>
import axios from "axios";
import { ref, onMounted } from "vue";

const weeklyWinner = ref({
  loading: true,
  data: null,
});

const getWeeklyWinner = async () => {
  try {
    const response = await axios.get(
      "https://bipfwudnwwrbfdqyokvt.functions.supabase.co/weekly-winner"
    );
    weeklyWinner.value.data = response.data.data;
    weeklyWinner.value.loading = false;
  } catch (error) {
    console.error(error);
  }
};

onMounted(() => {
  getWeeklyWinner();
});
</script>
